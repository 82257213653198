export default function Header(props) {
    return (
        <div className="flex border-b border-gray-200 pb-8 mb-12">
            <div className="mx-auto flex-1 lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{props.title}</h2>
                <p className="mt-2 text-lg leading-8 text-white" dangerouslySetInnerHTML={{ __html: props.description }}>
                </p>
            </div>
        </div>
    )
}