import { baseURL } from '../../rest/rest';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

const donationsAdapter = createEntityAdapter()
const initialState = donationsAdapter.getInitialState({
    types: [],
    donationStatus: 'idle',
    sendDonationStatus: 'idle'
})

export const fetchDonationTypes = createAsyncThunk('donations/fetchDonations', async () => {
    const response = await axios.get(baseURL + 'donation-type')
    return response.data
})

export const sendDonation = createAsyncThunk('auth/sendDonation', async (data, { getState }) => {
    const state = getState()
    const response = await axios.post(baseURL + 'donation', { ...data, user_token: state.auth.id })
    return response.data
})

export const donationsSlice = createSlice({
    name: 'donations',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDonationTypes.pending, (state, action) => {
                state.donationStatus = 'loading'
            })
            .addCase(fetchDonationTypes.fulfilled, (state, action) => {
                state.types = action.payload
                state.donationStatus = 'idle'
            })
            .addCase(sendDonation.pending, (state, action) => {
                state.sendDonationStatus = 'loading'
            })
            .addCase(sendDonation.fulfilled, (state, action) => {
                state.sendDonationStatus = 'idle'
            })
    }
})

export const { selectAll: donationTypes } = donationsAdapter.getSelectors(state => state.types)

export const { fillTypes } = donationsSlice.actions
export default donationsSlice.reducer