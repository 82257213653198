import Root from "../routes/root";
import Menu from "../routes/menu";
import Coupons from "../routes/coupons";
import Histories from "../routes/histories";
import { createBrowserRouter } from "react-router-dom";
import Donations from "../routes/donations";
import Help from "../routes/help";
import Blog from "../routes/blog";
import Login from "../routes/login";


export const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "menu",
                element: <Menu />
            },
            {
                path: "coupons",
                element: <Coupons />
            },
            {
                path: "forum",
                element: <Blog />
            },
            {
                path: "donations",
                element: <Donations />
            },
            {
                path: "histories",
                element: <Histories />
            },
            {
                path: "help",
                element: <Help />
            }
        ]
    },
]);