import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { fetchCoupons } from '../features/coupons/couponsSlice';
import { fetchDonationTypes } from "../features/donations/donationsSlice";
import { fetchHistories } from "../features/histories/historiesSlice";
import { fetchOrganizations } from "../features/organizations/organizationsSlice";
import { obtainAuthId } from "../features/auth/authSlice";
import { fetchBlog } from "../features/blog/blogSlice";
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, BoltIcon, BookOpenIcon, TicketIcon, BanknotesIcon, LifebuoyIcon, ChatBubbleLeftRightIcon, HomeIcon } from '@heroicons/react/24/outline'
import logo from '../images/logo.png'

const navigation = [
    {
        name: 'Inicio',
        description:
            '',
        href: '/',
        internal: true,
        icon: HomeIcon,
        colspan: 1,
    },
    {
        name: 'Empodérate',
        description:
            'Prepárate para aprender a través de nuestro juego 3D, atrévete a descubrir y completar todos los niveles. Podrás ganar muchos puntos que luego podras canjear por increibles productos y servcios.',
        href: 'https://p.verza.io/s/server_2UsIQVThJZsXrEqRCyp0bB',
        icon: BoltIcon,
        internal: false,
        colspan: 1,
    },
    {
        name: '¿Sabías que a mi me pasó?',
        description:
            'Conoce historias de otras personas que han pasado por situaciones o vivencias que pueden ser similar a la tuya. Recuerda que no estas solo y puedes ayudar a los demás compartiendo tus historias (Todos los nombres y fotografías son generados por Inteligencia artificial para garantizar el anonimato y seguridad)',
        href: '/histories',
        icon: BookOpenIcon,
        internal: true,
        colspan: 1,
    },
    {
        name: 'Canjes',
        description:
            '¿Ya tienes puntos? Descubre lo que puedes canjear con ellos en nuestro catálogo. Recuerda que puedes obtenerlos a traves del juego en Empodérate.',
        href: '/coupons',
        icon: TicketIcon,
        internal: true,
        colspan: 1,
    },
    {
        name: 'Donaciones',
        description:
            'Si quieres ayudar a ECO APP y beneficiar a niñas y niños guatemaltecos puedes aportar de diferentes maneras como con productos, dinero, tiempo, entre otras.',
        href: '/donations',
        icon: BanknotesIcon,
        internal: true,
        colspan: 1,
    },

    {
        name: 'Foro',
        description:
            'Si tienes dudas que quieres que sean respondidas por un experto en nuestro Foro puedes realizar consultas de forma anónima y también tener acceso a dudas de otras personas que pueden ser útiles para ti.',
        href: '/forum',
        icon: ChatBubbleLeftRightIcon,
        internal: true,
        colspan: 1,
    },
    {
        name: 'Orientar',
        description:
            'Conoce que organizaciones se encuentran en Guatemala que brindan apoyo y asesorias en relación a la educación sexual integral. ',
        href: '/help',
        icon: LifebuoyIcon,
        internal: true,
        colspan: '2',
    },
]

export default function Root() {
    const location = useLocation()
    const dispatch = useDispatch()
    dispatch(obtainAuthId())
    const authId = localStorage.getItem("random-auth-id")

    let navigateTo = location.pathname
    dispatch(fetchCoupons())
    dispatch(fetchDonationTypes())
    dispatch(fetchHistories())
    dispatch(fetchBlog())
    dispatch(fetchOrganizations())

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "RouterView" });
    }, [location]);

    if (navigateTo === "/") {
        if (authId === null) {
            navigateTo = "/login"
        } else {
            navigateTo = "/menu"
        }
        window.location.href = navigateTo
    }
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <>
            <div className="bg-gray-900">
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img src={logo} alt="Logo" className="w-16 mx-auto" />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) => (
                                <a key={item.name} href={item.href} target={item.internal ? '_self' : '_blank'} rel='noreferrer' className="text-sm font-semibold leading-6 text-white">
                                    <item.icon className="h-6 w-6 text-white inline" aria-hidden="true" /> {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        </div>
                    </nav>
                    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <div className="fixed inset-0 z-50" />
                        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                            <div className="flex items-center justify-between">
                                <a href="/" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Your Company</span>
                                    <img
                                        className="h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                        alt=""
                                    />
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-400"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/25">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        <a
                                            href="/"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>

                <div className="relative isolate pt-14">
                    <div
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div className="py-12 sm:py-12 lg:pb-40 min-h-full">
                        <Outlet />
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div>parent</div>
                <Outlet />
            </div> */}
        </>
    );
}