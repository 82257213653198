import { configureStore } from '@reduxjs/toolkit'
import donationsReducer from '../features/donations/donationsSlice'
import couponsReducer from '../features/coupons/couponsSlice'
import historiesReducer from '../features/histories/historiesSlice'
import organizationsReducer from '../features/organizations/organizationsSlice'
import authReducer from '../features/auth/authSlice'
import departmentsReducer from '../features/departments/departmentsSlice'
import blogReducer from '../features/blog/blogSlice'


export default configureStore({
    reducer: {
        donations: donationsReducer,
        coupons: couponsReducer,
        histories: historiesReducer,
        organizations: organizationsReducer,
        auth: authReducer,
        departments: departmentsReducer,
        blog: blogReducer
    }
})