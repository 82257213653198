import { useSelector, useDispatch } from 'react-redux'

import Header from '../../components/header';
import Modal from '../../components/modal'
import { useState } from 'react';
import { fetchHistories, sendHistory } from '../../features/histories/historiesSlice';

export default function Histories() {
    const histories = useSelector(state => state.histories.histories)
    const uploadingHistory = useSelector(state => state.histories.sendHistoryStatus)
    const [detailModalVisible, setDetailModalVisible] = useState(false)
    const [newHistoryModalVisible, setNewHistoryVisible] = useState(false)
    const [selectedHistory, setSelectedHistory] = useState(false)

    const selectHistory = (history) => {
        setDetailModalVisible(true)
        setSelectedHistory(history)
    }

    const dispatch = useDispatch()

    function handleSubmit(e) {
        e.preventDefault()
        var formData = new FormData(document.querySelector('form'))

        const title = formData.get("title");
        const content = formData.get("content");
        dispatch(sendHistory({ title, content })).then(() => dispatch(fetchHistories()))
        setNewHistoryVisible(false)
    }
    return (
        <div>
            <Modal setVisibility={setNewHistoryVisible} visible={newHistoryModalVisible}>
                <h2 className='text-xl text-white font-bold'>Mi historia</h2>
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-full">
                            <label className="block text-sm font-medium leading-6 text-white">Título</label>
                            <div className="mt-2">
                                <input type="text" name="title" id="title" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div className="sm:col-span-full">
                            <label className="block text-sm font-medium leading-6 text-white">Mi historia</label>
                            <div className="mt-2">
                                <textarea type="text" name="content" id="content" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={uploadingHistory === 'loading'}
                        className="mt-8 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Enviar
                    </button>
                </form>
            </Modal>
            <Modal setVisibility={setDetailModalVisible} visible={detailModalVisible}>
                <div className="mx-auto px-6 lg:px-8">
                    <div className="mx-auto lg:mx-0">
                        <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">A mi me pasó</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{selectedHistory.title}</h1>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
                        <div className="relative lg:order-last lg:col-span-5">
                            <figure className="border-l border-indigo-600 pl-8">
                                <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-300">
                                    <p>
                                        “Vel ultricies morbi odio facilisi ultrices accumsan donec lacus purus. Lectus nibh ullamcorper ac
                                        dictum justo in euismod. Risus aenean ut elit massa. In amet aliquet eget cras. Sem volutpat enim
                                        tristique.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-8 flex gap-x-4">
                                    <img
                                        src={"https://i.pravatar.cc/150?u=" + selectedHistory.fake_name}
                                        alt=""
                                        className="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50"
                                    />
                                    <div className="text-sm leading-6">
                                        <div className="font-semibold text-white">{selectedHistory.fake_name}</div>
                                        <div className="text-gray-400">{selectedHistory.formatted_created}</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="max-w-xl text-base leading-7 text-gray-400 lg:col-span-7">
                            {selectedHistory.content}
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <Header title="A mi me pasó" description="Cuenta tu historia de forma anónima. ¿Cuál ha sido tu experiencia en temas de educación sexual integral? ¿Qué te sucedió? Ayudanos a que más personas conozcan lo que es normal en nuestros cuerpos con tu experiencia.
                 <br> <br>Las historias son anónimas. Los nombres e imagines se generan con inteligencia artificial para darle una identidad a las historias" />
                <div className="mx-auto grid grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {histories.map((history) => (
                        <article onClick={() => selectHistory(history)} key={history.id} className="cursor-pointer flex max-w-xl flex-col items-start justify-between">
                            <div className="flex items-center gap-x-4 text-xs">
                                <time dateTime={history.formatted_created} className="text-white">
                                    {history.formatted_created}
                                </time>
                            </div>
                            <div className="group relative">
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                    {/* <a href={history.href}> */}
                                    <span className="absolute inset-0" />
                                    {history.title}
                                    {/* </a> */}
                                </h3>
                                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-400">{history.content}</p>
                            </div>
                            <div className="relative mt-8 flex items-center gap-x-4">
                                <img src={"https://i.pravatar.cc/150?u=" + history.fake_name} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                                <div className="text-sm leading-6">
                                    <p className="font-semibold text-white">
                                        {/* <a href={post.author.href}> */}
                                        <span className="absolute inset-0" />
                                        {history.fake_name}
                                        {/* </a> */}
                                    </p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div >
            <button
                type="button"
                onClick={() => setNewHistoryVisible(true)}
                className=" mx-auto block rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Compartir mi historia
            </button>
        </div >
    );
}