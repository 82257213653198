import { baseURL } from '../../rest/rest';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

const couponsAdapter = createEntityAdapter()
const initialState = couponsAdapter.getInitialState({
    coupons: [],
    couponStatus: 'idle'
})

export const fetchCoupons = createAsyncThunk('coupons/fetchcoupons', async () => {
    const response = await axios.get(baseURL + 'coupon')
    return response.data
})

export const couponsSlice = createSlice({
    name: 'coupons',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCoupons.pending, (state, action) => {
                state.couponStatus = 'loading'
            })
            .addCase(fetchCoupons.fulfilled, (state, action) => {
                state.coupons = action.payload
                state.couponStatus = 'idle'
            })
    }
})

export default couponsSlice.reducer