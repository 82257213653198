import { useSelector } from 'react-redux'
import Header from '../../components/header'

export default function Coupons() {
    const couponsList = useSelector(state => state.coupons.coupons)
    return (
        <div className="w-3/4 mx-auto">
            <Header title="Canjes" description="Al completar el videojuego de Empodérate podras canjear uno de los siguientes productos.  Podras recoger el producto en Forum Zona Viva Zona 10, los días miércoles de 7am a 4pm. Para coordinar la entrega con un miembro del equipo debes escribir al correo <a href='mailto:mujeresticsgt@gmail.com'>mujeresticsgt@gmail.com</a>" />
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {couponsList.map((coupon) => (
                    <li
                        key={coupon.id}
                        className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-800 text-center shadow"
                    >
                        <div className="flex flex-1 flex-col p-8">
                            <img className="mx-auto h-40 flex-shrink-0 rounded-full" src={
                                "https://api.eco-app.org/storage/" + coupon.photo} alt="" />
                            <h3 className="mt-6 text-md font-medium text-white">{coupon.title}</h3>
                            <dl className="mt-1 flex flex-grow flex-col justify-between">
                                <dt className="sr-only">Content</dt>
                                <dd className="text-sm text-gray-400">{coupon.content}</dd>
                            </dl>
                        </div>
                    </li>
                ))}
            </ul>
            <div class="text-white mt-4">
                <h2 class="text-white">Requisitos para el canje:</h2>
                <ul class="mx-2">
                    <li>- Presentar copia de partida de nacimiento o DPI</li>
                    <li>- La edad debe ser de 14 a 19 años</li>
                    <li>- Solo se permite realizar un canje por persona</li>
                    <li>- Debes ser de nacionalidad guatemalteca </li>
                    <li>- Presentar el código que se genera al finalizar el videojuego</li>
                </ul>
                <p class="mt-4">Los canjes serán validos hasta agotar existencias.</p>
            </div>
        </div>
    );
}