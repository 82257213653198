import { baseURL } from '../../rest/rest';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

const departmentsAdapter = createEntityAdapter()
const initialState = departmentsAdapter.getInitialState({
    list: [],
    departmentStatus: 'idle'
})

export const fetchDepartments = createAsyncThunk('departments/fetchdepartments', async () => {
    const response = await axios.get(baseURL + 'department')
    return response.data
})

export const departmentsSlice = createSlice({
    name: 'departments',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDepartments.pending, (state, action) => {
                state.departmentStatus = 'loading'
            })
            .addCase(fetchDepartments.fulfilled, (state, action) => {
                state.list = [...action.payload]
                state.departmentStatus = 'idle'
            })
    }
})


export const { allDepartments } = departmentsSlice.actions


export default departmentsSlice.reducer