import { useSelector, useDispatch } from 'react-redux'
import Header from "../../components/header";
import { sendDonation } from "../../features/donations/donationsSlice";

export default function Donations() {
    const donationTypes = useSelector(state => state.donations.types)
    const sendDonationStatus = useSelector(state => state.donations.sendDonationStatus)

    const dispatch = useDispatch()

    function handleSubmit(e) {
        e.preventDefault()
        var formData = new FormData(document.querySelector('form'))

        const name = formData.get("name");
        const email = formData.get("email");
        const phone = formData.get("phone");
        const donation_type_id = formData.get("donation_type_id");
        const comments = formData.get('comments')
        dispatch(sendDonation({ name, email, phone, donation_type_id, comments }))
            .then(() => alert("Datos de donación recibidos."))
    }


    return (<div className="w-3/4 mx-auto">
        <Header title="Donaciones" description="Ayúdanos a seguir con esta misión." />
        <form onSubmit={e => handleSubmit(e)}>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-white">Organización</label>
                    <div className="mt-2">
                        <input type="text" name="name" id="name" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                {/* <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-white">Nombre de contacto</label>
                    <div className="mt-2">
                        <input type="text" name="contact_name" id="contact_name" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                    </div>
                </div> */}
                <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-white">Correo electrónico</label>
                    <div className="mt-2">
                        <input type="text" name="email" id="email" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-white">Teléfono</label>
                    <div className="mt-2">
                        <input type="text" name="phone" id="phone" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-white">Tipo de donación</label>
                    <div className="mt-2">
                        <select id="donation_type_id" name="donation_type_id"
                            className="block w-full rounded-md border-0 bg-white/5 p-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
                            {
                                donationTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="sm:col-span-full">
                    <label className="block text-sm font-medium leading-6 text-white">Comentarios</label>
                    <div className="mt-2">
                        <input type="text" name="comments" id="comments" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <button
                    type="submit"
                    disabled={sendDonationStatus === 'loading'}
                    className="sm:col-span-full mt-8 mx-auto inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Enviar
                </button>
            </div>
        </form>
    </div>)
}