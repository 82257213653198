import { useSelector } from 'react-redux'
import Header from '../../components/header'
import { GlobeAltIcon, PhoneIcon } from '@heroicons/react/24/outline'

export default function Help() {
    const organizations = useSelector(state => state.organizations.organizations)
    return (
        <div className="w-3/4 mx-auto">
            <Header title="Orientar" description="Aqui te mostramos un directorio de organizaciones en Guatemala que apoyan y brindan servicios de educación sexual integral, 
            algunos de ellos son gratuitos. Sin embargo, también hay organizaciones privadas y públicas con diferentes enfoque
            s (Psicología, productos, servicios médicos, entre otros) relacionados al tema. 
            Si te interesa que tu organización apareza en está sección puedes completar la información <a class='text-blue-500' href='https://forms.gle/99QEcnEyF311ejJc9' target='_blank'>aquí (https://forms.gle/99QEcnEyF311ejJc9)</a>." />
            <ul className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {organizations.map((organization) => (
                    <li
                        key={organization.id}
                        className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-800 text-center shadow"
                    >
                        <div className="flex flex-1 flex-col p-8">
                            <img className="mx-auto h-40 flex-shrink-0 rounded-full" src={"https://api.eco-app.org/storage/" + organization.logo} alt="" />
                            <h3 className="mt-6 text-md font-medium text-white">{organization.name}</h3>
                            <dl className="mt-1 flex flex-grow flex-col justify-between">
                                <dt className="sr-only">Content</dt>
                                <dd className="text-sm text-gray-400">{organization.goal}</dd>
                            </dl>
                            <div className="mt-4 flex justify-center">
                                <a href={organization.website} target="_blank"
                                    rel="noreferrer"
                                    className="inline-flex mx-1 items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <GlobeAltIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Página web
                                </a>
                                <a href={"tel:" + organization.phone} target="_blank"
                                    rel="noreferrer"
                                    className="inline-flex mx-1 items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Teléfono
                                </a>
                            </div>
                            <div className="mt-4 flex justify-center">
                                {
                                    organization.social_networks.map((socialNetwork) => <a
                                        target="_blank"
                                        key={socialNetwork.id}
                                        rel="noreferrer"
                                        href={socialNetwork.pivot.link}>
                                        <img src={"https://api.eco-app.org/storage/" + socialNetwork.logo} alt="" className="w-8 mx-2" />
                                    </a>)

                                }
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div >
    );
}