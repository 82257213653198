import { fetchDepartments } from '../../features/departments/departmentsSlice'
import logo from '../../images/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { login, setAuthId } from '../../features/auth/authSlice'




export default function Login() {
    const departments = useSelector(state => state.departments.list)
    const loginStatus = useSelector(state => state.auth.loginStatus)
    const dispatch = useDispatch()
    if (departments.length === 0) {
        dispatch(fetchDepartments())
    }
    function formLogin(e) {
        e.preventDefault()
        var formData = new FormData(document.querySelector('form'))

        const birthdate = formData.get("birthdate");
        const gender = formData.get("gender");
        const department_id = formData.get('department_id')
        console.log(birthdate, gender, department_id)
        dispatch(setAuthId())

        dispatch(login({ birthdate, gender, department_id }))
    }

    return <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-16 w-auto"
                    src={logo}
                    alt="ECO"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                    Registro
                </h2>
                <p className='text-gray-400 text-sm my-4'>
                    No guardamos ninguno de tus datos es compartido, buscamos mantener tu anonimato.
                </p>
                <p className='text-gray-400 text-sm'>
                    Por favor no compartir tus datos personales con nadie. De compartirlos no nos  hacemos responsables si les das a alguien dentro de la comunidad tus datos personales.
                </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={e => formLogin(e)}>
                    <div>
                        <label htmlFor="birthdate" className="block text-sm font-medium leading-6 text-white">
                            Birthdate
                        </label>
                        <div className="mt-2">
                            <input id="birthdate" name="birthdate" type="date" required className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="gender" className="block text-sm font-medium leading-6 text-white">
                            Género
                        </label>
                        <div className="mt-2">
                            <select id="gender" name="gender"
                                className="block w-full rounded-md border-0 bg-white/5 p-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="department_id" className="block text-sm font-medium leading-6 text-white">
                            Departamento
                        </label>
                        <div className="mt-2">
                            <select id="department_id" name="department_id"
                                className="block w-full rounded-md border-0 bg-white/5 p-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
                                {
                                    departments.map(type => <option key={type.id} value={type.id}>{type.name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={loginStatus === "loading"}
                        className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                        Ingresar
                    </button>

                </form>


            </div>
        </div>
    </>
}