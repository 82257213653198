import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 } from 'uuid'
import axios from 'axios'
import { baseURL } from '../../rest/rest';

const authAdapter = createEntityAdapter()
const initialState = authAdapter.getInitialState({
    id: null,
    loginStatus: false
})

export const login = createAsyncThunk('auth/fetchLogin', async (data, { getState }) => {
    const state = getState()
    const response = await axios.post(baseURL + 'anonymous-register', { ...data, user_token: state.auth.id })
    return response.data
})

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthId(state, action) {
            const id = v4()
            localStorage.setItem("random-auth-id", id)
            state.id = id
        },
        clearAuthId(state, action) {
            state.id = null
        },
        obtainAuthId(state, action) {
            const authId = localStorage.getItem("random-auth-id")
            state.id = authId
        }
    },
    extraReducers: builder => {
        builder
            .addCase(login.pending, (state, action) => {
                state.loginStatus = 'loading'
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loginStatus = 'idle'
                window.location.href = "/menu"
            })
    }
})


export const { obtainAuthId, setAuthId, clearAuthId } = authSlice.actions
export default authSlice.reducer