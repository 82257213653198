import logo from '../../images/logo.png'

export default function Menu() {
    return (
        <div class="flex items-center h-full mt-24">
            <div class="flex-1">
                <h2 class="text-2xl my-8 text-center font-bold text-white">MujeresTics Guatemala & Planned Parenthood</h2>
                <img src={logo} alt="Logo" className="w-96 mx-auto" />
            </div>
            <div class="flex-1">
                <a
                    href="/forum"
                    className=" mx-auto rounded-full bg-indigo-600 px-8 py-4 text-md lg:text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    ¡Empieza a aprender sobre educación sexual!
                </a>
            </div>

        </div>
    );
}