import { baseURL } from '../../rest/rest';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

const historiesAdapter = createEntityAdapter()
const initialState = historiesAdapter.getInitialState({
    histories: [],
    historyStatus: 'idle',
    sendHistoryStatus: 'idle'
})

export const fetchHistories = createAsyncThunk('histories/fetchhistories', async () => {
    const response = await axios.get(baseURL + 'history')
    return response.data
})
export const sendHistory = createAsyncThunk('auth/sendHistory', async (data, { getState }) => {
    const state = getState()
    const response = await axios.post(baseURL + 'history', { ...data, user_token: state.auth.id })
    return response.data
})

export const historiesSlice = createSlice({
    name: 'histories',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchHistories.pending, (state, action) => {
                state.historyStatus = 'loading'
            })
            .addCase(fetchHistories.fulfilled, (state, action) => {
                state.histories = action.payload
                state.historyStatus = 'idle'
            })
            .addCase(sendHistory.pending, (state, action) => {
                state.sendHistoryStatus = 'loading'
            })
            .addCase(sendHistory.fulfilled, (state, action) => {
                state.sendHistoryStatus = 'idle'
            })
    }
})

export default historiesSlice.reducer