import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../../components/backbutton'
import Header from '../../components/header'
import { useState } from 'react'
import Modal from '../../components/modal'
import { sendBlogEntry } from '../../features/blog/blogSlice'

export default function Coupons() {
    const blogEntries = useSelector(state => state.blog.entries)
    const uploadingQuestionStatus = useSelector(state => state.blog.sendBlogEntryStatus)
    const [newEntryModalVisible, setNewEntryModalVisible] = useState(false)

    const dispatch = useDispatch()

    function handleSubmit(e) {
        e.preventDefault()
        var formData = new FormData(document.querySelector('form'))

        const question = formData.get("question");
        dispatch(sendBlogEntry({ question })).then(() => alert("Tu pregunta fue enviada para revisión y posterior respuesta."))
        setNewEntryModalVisible(false)
    }

    let [selectedEntry, setSelectedEntry] = useState(null)
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <div className="w-3/4 mx-auto">
            <Modal setVisibility={setNewEntryModalVisible} visible={newEntryModalVisible}>
                <h2 className='text-xl text-white font-bold'>Nueva pregunta</h2>
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-full">
                            <label className="block text-sm font-medium leading-6 text-white">Pregunta</label>
                            <div className="mt-2">
                                <input type="text" name="question" id="question" className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={uploadingQuestionStatus === 'loading'}
                        className="mt-8 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Enviar
                    </button>
                </form>
            </Modal>
            <Header title="Foro" description="Si tienes dudas que quieres que sean respondidas por un experto en nuestro Foro puedes realizar consultas de forma anónima y también tener acceso a dudas de otras personas que pueden ser útiles para ti." />
            <div className="flex">
                <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                    <ul className="-mx-2 space-y-1 rounded-md">
                        <li>
                            <span
                                // href={item.href}
                                onClick={() => setNewEntryModalVisible(true)}
                                className='cursor-pointer text-gray-200 hover:text-indigo-200 hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
                            >
                                Hacer una pregunta
                            </span>
                        </li>
                        {blogEntries.map((entry) => (
                            <li key={entry.id}>
                                <span
                                    // href={item.href}
                                    onClick={() => setSelectedEntry(entry)}
                                    className={classNames(
                                        selectedEntry === entry ? 'bg-gray-500 text-indigo-200' : 'text-gray-700 hover:text-indigo-200 hover:bg-gray-800',
                                        'cursor-pointer group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    {entry.question}
                                </span>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="flex-1 ml-8">
                    <Content entry={selectedEntry} />
                </div>
            </div>
            <BackButton />
        </div>
    );
}

function Content({ entry }) {
    if (entry === null) {
        return <span className="text-white italic">Selecciona una pregunta para ver la respuesta</span>
    } else {
        return (<div>
            <h2 className='text-2xl font-bold text-white'>{entry.question}</h2>
            <p className='mt-4 text-gray-400'>{entry.answer}</p>
        </div>)
    }
}