import { baseURL } from '../../rest/rest';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

const organizationsAdapter = createEntityAdapter()
const initialState = organizationsAdapter.getInitialState({
    organizations: [],
    organizationStatus: 'idle'
})

export const fetchOrganizations = createAsyncThunk('organizations/fetchorganizations', async () => {
    const response = await axios.get(baseURL + 'organization')
    return response.data
})

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchOrganizations.pending, (state, action) => {
                state.organizationStatus = 'loading'
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                state.organizations = action.payload
                state.organizationStatus = 'idle'
            })
    }
})

export default organizationsSlice.reducer