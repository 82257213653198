import { baseURL } from '../../rest/rest';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

const blogAdapter = createEntityAdapter()
const initialState = blogAdapter.getInitialState({
    entries: [],
    fetchBlogStatus: 'idle',
    sendBlogEntryStatus: 'idle'
})

export const fetchBlog = createAsyncThunk('blog/fetchBlog', async () => {
    const response = await axios.get(baseURL + 'blog')
    return response.data
})

export const sendBlogEntry = createAsyncThunk('auth/sendBlogEntry', async (data, { getState }) => {
    const state = getState()
    const response = await axios.post(baseURL + 'blog', { ...data, user_token: state.auth.id })
    return response.data
})

export const blogSlice = createSlice({
    name: 'blog',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchBlog.pending, (state, action) => {
                state.fetchBlogStatus = 'loading'
            })
            .addCase(fetchBlog.fulfilled, (state, action) => {
                state.entries = action.payload
                state.fetchBlogStatus = 'idle'
            })

            .addCase(sendBlogEntry.pending, (state, action) => {
                state.sendBlogEntryStatus = 'loading'
            })
            .addCase(sendBlogEntry.fulfilled, (state, action) => {
                state.sendBlogEntryStatus = 'idle'
            })
    }
})

export default blogSlice.reducer